<template>
  <div>
    <div class="vx-row mb-4">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2 class="capitalize">{{$t($route.params.type)}}</h2>
      </div>
      <div v-if="$store.state.AppActiveUser.userRole === 'admin'" class="vx-col w-full lg:w-1/2 items-end">
          <div class="flex justify-between">
            <span class="font-semibold"></span>
            <span class="font-medium text-primary cursor-pointer">
              <vs-button
                @click="newWarehouseUnit('Add')"
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                size="large"
              >
              </vs-button>
            </span>
          </div>
      </div>
    </div>
    <div v-if="$route.params.type === 'slots'" class="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
      <template v-if="warehouseUnitsLoading">
        <shipblu-card v-for="item in 5" :key="item.index" :cardLoading="true" class="p-5">
        </shipblu-card>
      </template>
      <template v-else>
        <shipblu-card class="p-4" v-for="(warehouseUnit, index) in warehouseUnits" :key="index">
          <div class="relative">
            <span class="absolute left-0">
              <vx-tooltip text="Is Full">
                <feather-icon icon="LockIcon" svgClasses="h-4 w-4" class="mr-2 text-blue-900" v-if="warehouseUnit.is_full"/>
              </vx-tooltip>
            </span>
            <span class="absolute right-0">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="newWarehouseUnit('Edit', warehouseUnit)"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteWarehouseUnit(warehouseUnit)"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </span>
            <div class="flex justify-center">
              <p class="text-primary text-base font-semibold">{{warehouseUnit.aisle_sign}}</p>    
            </div>
          </div>
          <div class="flex justify-between gap-2 mt-4">
            <p class="text-darkgray text-base">{{$t('No. Packages')}}</p>
            <p class="text-blue-900 text-base font-medium text-right">{{warehouseUnit.num_packages}}</p>
          </div>
          <div class="flex justify-between gap-2">
            <p class="text-darkgray text-base">{{$t('Agent')}}</p>
            <p class="text-blue-900 text-base font-medium text-right">{{warehouseUnit.checkout_agent ? warehouseUnit.checkout_agent.first_name :''}} {{warehouseUnit.checkout_agent ? warehouseUnit.checkout_agent.last_name :''}}</p>
          </div>
          <div class="flex justify-between gap-2">
            <p class="text-darkgray text-base">{{$t('Checkout Date')}}</p>
            <p class="text-blue-900 text-base font-medium text-right">{{warehouseUnit.checkout_date ? new Date(warehouseUnit.checkout_date).toLocaleDateString('fr-CA') : ''}}</p>
          </div>
        </shipblu-card>
      </template>
    </div>
    <shipblu-table
      v-else
     :sst="true"
     :hideHeader="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      :data="warehouseUnits"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Aisle Sign')}}</shipblu-th>
        <shipblu-th>{{$t('Capacity')}}</shipblu-th>
        <shipblu-th>{{$t('No. Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].aisle_sign" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].aisle_sign }}
              </p>
            </div>
          </shipblu-td> 

          <shipblu-td class="order-4" :data="data[indextr].aisle_sign">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Aisle Sign')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].aisle_sign }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].capacity">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Capacity')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].capacity }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].num_packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('No. Packages')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].num_packages }}
            </p>
          </shipblu-td>
          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">

                  <!-- ACTIONS FOR ON CREATED -->
                  <vs-dropdown-item
                    @click="newWarehouseUnit('Edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteWarehouseUnit(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-warehouse-unit :type="type" :warehouses="warehouses" :warehouseDic="warehouseDic" :addWarehouseUnitModal="addWarehouseUnitModal"
      @addWarehouseUnitModal="addWarehouseUnitModal = $event" :warehouseUnit="warehouseUnit"
      @addWarehouseUnit="addWarehouseUnit" @updateWarehouseUnit="updateWarehouseUnit"
      @loadWarehousesUnits="loadWarehousesUnits" @toWarehouse="toWarehouse = $event" @type="type = $event"></add-warehouse-unit>
  </div>
</template>


<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import AddWarehouseUnit from './components/AddWarehouseUnit.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      offset: 0,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      warehouseUnit: {
        aisle_sign: '',
        barcode: '',
        capacity: 1,
        to_warehouse: {}
      },
      type: '',
      deleteData: {},
      warehouseUnits: [],
      pudos: [],
      selected: [],
      governorates: [],
      cities: [],
      zones: [],
      addWarehouseUnitModal: false,
      warehousesDic: {},
      warehouseDic: {},
      tableLoader: false,
      warehouses: [],
      warehouseUnitsLoading: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadWarehousesUnits()
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      this.warehouses = warehouses
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.warehouses.forEach(element => {
        this.warehouseDic[element.id] = element
      })
    },
    loadWarehousesUnits () {
      this.warehouseUnitsLoading = true
      this.tableLoader = true
      const query = this.$route.params.type === 'slots' ? '&offset=0&limit=250' : `&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/${this.$route.params.type}/?warehouse__code=${this.$route.params.warehouseID}${query}`, 'get', null, true,
        (response) => {
          this.warehouseUnits = response.data.results
          this.warehouseUnitsLoading = false
          this.tableLoader = false
        }
      )
    },
    newWarehouseUnit (type, data) {
      this.type = type
      if (type === 'Add') {
        this.warehouseUnit = {
          aisle_sign: '',
          barcode: '',
          capacity: 1,
          warehouse: this.warehousesDic[this.$route.params.warehouseID],
          to_warehouse: {}
        }
      } else {
        this.warehouseUnit = data
        this.warehouseUnit.warehouse = this.warehousesDic[this.$route.params.warehouseID]
      }
      this.addWarehouseUnitModal = true
    },
    addWarehouseUnit () {
      this.warehouseUnit.to_warehouse = this.toWarehouse.id
      sendRequest(true, false, this, `api/v1/${this.$route.params.type}/`, 'post', [this.warehouseUnit], true,
        () => {
          this.addWarehouseUnitModal = false
          this.loadWarehousesUnits()
        }
      )
    },
    updateWarehouseUnit () {
      this.warehouseUnitsLoading = true
      const sendData = {
        capacity : this.warehouseUnit.capacity,
        aisle_sign : this.warehouseUnit.aisle_sign,
        barcode : this.warehouseUnit.barcode,
        to_warehouse : this.toWarehouse.id
      }
      sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.warehouseUnit.id}/`, 'patch', sendData, true,
        () => {
          this.addWarehouseUnitModal = false
          this.loadWarehousesUnits()
        }
      )
    },
    deleteWarehouseUnit (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      this.warehouseUnitsLoading = true
      sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Slot'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadWarehousesUnits()
        }
      )
    }
  },
  components: {
    AddWarehouseUnit,
    ShipbluCard,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
    this.loadWarehousesUnits()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
